.right-container{
    border: 2px solid #D7C6FF;
border-radius: 4rem;
height: 75vh;
}
@media only screen and (max-width: 1200px) {
    .right-container{
        border: none;
        border-radius: none;
        height: 0vh;
    }
   
}