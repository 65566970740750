html {
  font-size: 10px;
}
/* @media (max-width: 900px) {
  html { font-size: 15px; }
}
@media (max-width: 400px) {
  html { font-size: 13px; }
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent radial-gradient(closest-side at 50% 50%, #FDFCFF 0%, #FBF9FF 31%, #F9F6FF 61%, #EDE6FF 100%) 0% 0% no-repeat padding-box;
  height: 100vh;
  color: var(--secondry);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --primary: #734BD1;
  --secondry: #595959;
  --light-grey:#FAF8FF;
  --ligt-grey-100:#F3F3F6;
  --light-grey-200:#CFBCFF;
  --white:#FFFFFF;
  --danger: #ff2e47;
}
#root{
  height: 100%;
}
.disable-btn{
  opacity: 0.4 !important;
}


@media only screen and (max-width: 1200px) {
  body{
    background: var(--white);
  }
}