.header{
    /* grid-template-rows: 10% 90%; */
    /* padding: 2rem 2rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
    height: 10%; */
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    height: 7%;
}
.logo-wrapper img{
    height: 100%;
}
.logo-wrapper{
   height: 4rem;
}
.header-right{
    display: flex;
    /* justify-content: center; */
    /* justify-items: center; */
    align-items: center;
}
.avatar-wrapper .css-2s90m6-MuiAvatar-root{
    background-color: var(--light-grey);
    color: var(--secondry);
    font-size: medium;
    font-weight: 600;
    border: 2px solid #D2C3F1;
}
.header-right svg{
    font-size: x-large;
    color: var(--primary);
}
@media only screen and (max-width: 1200px) {
    .notMobile{
        display: none;
    }
    .header{
        /* grid-template-rows: 10% 90%; */
        padding: 1rem;
        display: block;
        text-align: center;
        height: auto;
    }   
}