.pdf-header{
    height: 10%;
    color: var(--white);
    background-color: var(--primary);
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pdf-icons{
    display: flex;
    align-items: center;
}
.file-name{
    font-size: medium;
}
.pdf-save-btn{
    background: var(--white) 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: none;
    color: var(--primary);
    width: 8rem;
    padding: 0.7rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
}
.file-wrapper{
    height: 90%;
}
.webviewer{
    height: 90% !important;
    overflow-y: hidden;
}
@media only screen and (max-width: 1200px) {
    .SignatureModal .container {
        border-radius: 30px !important;
        width: 640px !important;
    }
    .pdf-header{
        height: 10%;
        background-color: var(--white);
        color: var(--secondry);
        padding: 1rem 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* box-shadow: 0px 3px 6px var(--light-grey-200); */
    }
    .pdf-save-btn{
        background: var(--primary) 0% 0% no-repeat padding-box;
        border-radius: 24px;
        border: none;
        color: var(--white);
        width: 8rem;
        padding: 0.7rem 1rem;
        font-size: 1.2rem;
        font-weight: 600;
    }
    .file-name{
        font-size: 2rem;
        font-weight: 500;
    }
}