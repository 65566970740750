.btn-wrapper{
 /* display: flex;
 align-items: center; */
 text-align: center;
 margin-top: 1rem;
}
.btn-primary{
    width: 15rem;
    padding: 0.5rem 1rem;
    background: var(--primary) 0% 0% no-repeat padding-box;
    border: none;
    color: white;
    font-size: medium;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-weight: 500;
}
.btn-secondary{
    width: 15rem;
    font-size: medium;
    padding: 0.5rem 1rem;
    font-weight: 500;
    color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 5px;
    background-color: var(--white);
}   
.disclaimer-btn-wrapper{
    margin-top: 3rem;
}
