.reason-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    border: none;
    box-shadow: 24;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 60px;
    padding: 4rem;
}
.autocomplete-wrapper{
    margin: 3rem auto;
    display: flex;
    justify-content: center;
}
.close-icon{
    text-align: right;
}
.close-icon svg{
    color: var(--primary);
    font-size: 3rem;
}
.reason-title{
    margin: 1rem 0;
    color: var(--secondry);
    text-align: center;
}
.reason-modal-body{
    align-items: center;
}
.btn-reason-wrapper{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 1.3rem !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 1.4rem !important;
    background: white;
    font-weight: 600 !important;
    color: var(--secondry) !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: var(--secondry) !important;
    /* color: var(--primary) !important; */
    font-size: 1.5rem !important;
   
}
.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
    font-size: 1.6rem !important;
}
.css-gcwvw8-MuiAutocomplete-root .MuiAutocomplete-input{
    font-size: 1.6rem;
}
.MuiAutocomplete-option{
    font-size: 1.6rem !important;
}
/* .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
    border: 1px solid var(--primary) !important;
} */
@media only screen and (max-width: 1200px) {
    .reason-wrapper{
        width: 80vw;
    }
}