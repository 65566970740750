.title{
    text-align: center;
    letter-spacing: 0px;
    color: #595959;
    margin: 4rem 0;
}
.content-wrapper{
    margin: 0 7rem;
    height: 35vh;
    overflow: hidden;
    overflow-y: auto;
    text-align: left;
}
.content-wrapper p{
    font-size: 1.2rem;
}
::-webkit-scrollbar {
    width: .5rem;
    height: 1rem;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background: var(--primary); 
  }
  ::-webkit-scrollbar-thumb {
   height: 10px;
    border-radius: 10px;
  }
  @media only screen and (max-width: 1200px) {
    .title{
      margin: 1rem 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .content-wrapper{
      margin: 0 1rem;
      height: 30vh;
    }
  }