.disclaimer-img-container img{
    width: 100%;
    height: 100%;
}
.disclaimer-img-container{
    height: 100%;
    width: 40rem;
}

@media only screen and (max-width: 768px) {
    .disclaimer-img-container{
        height: 100%;
        width: 60vw;
        margin: auto;
    } 
}
@media only screen and (max-width: 1200px) {
    .disclaimer-img-container{
        height: 100%;
        width: 40vw;
        margin: auto;
    } 
}